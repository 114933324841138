import React, { useState, useEffect, useRef } from 'react'
import { Link, navigate } from 'gatsby'
import toast from 'react-hot-toast'
// Components
import {
  AddCoupon,
  CartProductCard,
  Loading,
  Nexio3dsModal,
  OrderConfirmation,
  OrderDetails,
  OrderFailed,
  PageHeader,
  PaymentDetails,
  PayPalButton,
  PcAgreement,
  PcOffer,
  Primary as PrimaryButton,
  Seo,
  ShippingDetails,
  RenewalModalForAmb,
} from '../components'
// Styles
import { Wrapper, Container, StyledSpan } from '../styles/common'
// Context
import { usePrismic } from '../context/PrismicContext'
import { useAuthContext } from '../context/AuthContext'
import { useCartContext } from '../context/CartContext'
// Hooks
import useSetShipping from '../hooks/useSetShipping'
// Utils
import { OUT_OF_STOCK } from '../utils/productHelpers'
import analytics from '../utils/analytics'
import TotalSavings from '../components/Cart/TotalSavings'
import { completeNexioTransaction } from '../utils/cartHelpers'
// Services
import { auth } from '../services/firebase'
import { QService } from '../services/q-services'
import * as Sentry from '@sentry/react'

const initialCartState = {
  areTermsAccepted: false,
  orderError: null,
  orderNumber: null,
  orderStatus: null,
  receiveSmsEmail: false,
  showConfirmation: false,
  showOrderError: false,
  pendingTransactionUid: null,
}

const CartPage = () => {
  const [
    {
      areTermsAccepted,
      orderError,
      orderNumber,
      orderStatus,
      receiveSmsEmail,
      showConfirmation,
      showOrderError,
    },
    setCartState,
  ] = useState(initialCartState)
  const [nexio3DsUrl, setNexio3DsUrl] = useState('')
  const [showNexio3dsModal, setShowNexio3dsModal] = useState(false)
  const [nexioRedirectData, setNexioRedirectData] = useState({})
  const [isRenewalPending, setIsRenewalPending] = useState(false)

  const {
    localeCountryCode,
    prismicData: {
      prismicCartPage: {
        card_ending_in,
        continue_to_checkout,
        header,
        ignite_pickup,
        loading_cart,
        place_order,
        please_add_a_payment_method,
        please_add_shipping,
        processing_order,
        shipping_address_error,
        shipping_to,
        thanks_for_shopping_with,
        title,
        which_address_should_we_use,
        cart_max,
      },
      prismicHomepage: { event_name },
    },
  } = usePrismic()

  const {
    expiredSubscription,
    isAuthenticated,
    isEventSite,
    isVipSite,
    magentoUser,
    qUser,
    qUserUpline,
    userType,
    getSelectedCard,
    handleRefreshSite,
    updateQUser,
    showRenewalModalForAmb,
    setShowRenewalModalForAmb,
    manageReferral: { isEnrollmentComplete, isReferral, referralData },
  } = useAuthContext()

  const {
    buildCart,
    cartData,
    isAmbOfferInCart,
    isPcOfferInCart,
    isPlacingOrder,
    isShippingSet,
    manageCart: { placeOrder, doesCartHaveCBD },
    handleGetCartData,
    setIsPlacingOrder,
    upgradeUserAddPcFee,
  } = useCartContext()

  const { shippingAddress, shippingMethod } = useSetShipping(!!orderNumber)
  const { lastFourDigits, creditCardType } = getSelectedCard()

  const getNexioParams = () => {
    const params = new URLSearchParams(decodeURI(location.search))
    const nexioId = params.get('id')
    const nexioStatus = params.get('status')
    const nexioTransactionUid = params.get('tuid')
    const nexioOrderNumber = params.get('orderNumber')
    const nexioMOrderNumber = params.get('mOrderNumber')
    const nexioError = params.get('error')
    const nexioErrorMessage = params.get('message')
    const gatewayStatus = params.get('gatewayResponse[status]')
    return {
      nexioId,
      nexioStatus,
      nexioTransactionUid,
      nexioOrderNumber,
      nexioMOrderNumber,
      nexioError,
      nexioErrorMessage,
      gatewayStatus,
    }
  }

  const assertNexioParams = () => {
    const {
      nexioId,
      nexioStatus,
      nexioTransactionUid,
      nexioOrderNumber,
      nexioMOrderNumber,
      nexioError,
      nexioErrorMessage,
    } = getNexioParams()
    return (
      !(
        !nexioId ||
        !nexioStatus ||
        !nexioTransactionUid ||
        !nexioOrderNumber ||
        !nexioMOrderNumber
      ) ||
      (nexioError && nexioErrorMessage)
    )
  }

  const handleOrderError = (error, status) => {
    setCartState(prev => ({
      ...prev,
      orderError: error,
      orderStatus: status,
      showOrderError: true,
    }))
  }

  const reAddAuthHeader = () => {
    const user = auth.currentUser.toJSON()
    const token = user.stsTokenManager.accessToken
    QService.setToken(token)
  }

  const confirmTransaction = () => {
    setIsPlacingOrder(false)
    const {
      nexioStatus,
      nexioTransactionUid,
      nexioMOrderNumber,
      nexioError,
      nexioErrorMessage,
      gatewayStatus,
    } = getNexioParams()
    if (nexioError && nexioErrorMessage) {
      completeNexioTransaction(nexioTransactionUid, true)
        .then(() => {
          setCartState(prev => ({ ...prev, orderNumber: nexioMOrderNumber }))
          handleOrderError(nexioErrorMessage, gatewayStatus)
        })
        .catch(() => {
          setCartState(prev => ({ ...prev, orderNumber: nexioMOrderNumber }))
          handleOrderError(nexioErrorMessage, gatewayStatus)
        })
    } else if (nexioStatus === 'settled') {
      completeNexioTransaction(nexioTransactionUid, false)
        .then(() => {
          handleGetCartData()
          setCartState(prev => ({
            ...prev,
            orderNumber: nexioMOrderNumber,
            showConfirmation: true,
          }))
        })
        .catch(error => {
          console.log(error)
        })
    }
    window.sessionStorage.removeItem('nexio3ds')
  }

  useEffect(() => {
    if (
      auth.currentUser &&
      window.sessionStorage.getItem('nexio3ds') &&
      assertNexioParams()
    ) {
      reAddAuthHeader()
      confirmTransaction()
    }
  }, [auth.currentUser])

  const handleNexio3dsModalClose = () => {
    setShowNexio3dsModal(false)
    setNexio3DsUrl('')
    setIsPlacingOrder(true)
    completeNexioTransaction(nexioRedirectData.pendingTransactionUid, true)
      .then(() => {
        handleGetCartData()
        setCartState(prev => ({ ...prev, orderNumber: orderNumber }))
        setIsPlacingOrder(false)
        handleOrderError('User Cancelled', 'canceled')
      })
      .catch(() => {})
  }

  const handleNexio3dsModalYes = () => {
    setShowNexio3dsModal(false)
    window.sessionStorage.setItem('nexio3ds', 'true')
    window.location.href = nexio3DsUrl
    setIsPlacingOrder(true)
    setNexio3DsUrl('')
  }

  const handleSuccess = () => {
    localStorage.setItem('showArqModal', isAmbOfferInCart && 'true')
    analytics('purchase')
    setCartState(prev => ({
      ...prev,
      showConfirmation: true,
    }))
  }

  const handlePlaceOrder = async () => {
    setIsRenewalPending(true)
    if (isPcOfferInCart) {
      const data = {
        ...qUser,
        areTermsAccepted,
        receiveEmail: true,
        receiveText: true,
      }

      await updateQUser(data)
    }

    if (isAmbOfferInCart && !isEnrollmentComplete) {
      alert('Please complete your enrollment to purchase this offer.')
      navigate('/enrollment')
      return
    }
    if (!isShippingSet) {
      toast.error(shipping_address_error[0].text)
      return
    }
    if (!lastFourDigits) {
      toast.error(please_add_a_payment_method[0].text)
      return
    }

    window.scrollTo(0, 0) // scroll to top of page
    let { orderNumber, orderError, status, redirectData } = await placeOrder(
      setIsPlacingOrder,
      cartData,
      getSelectedCard,
      buildCart,
      handleGetCartData,
      false,
      null
    )
    setCartState(prev => ({ ...prev, orderNumber }))
    if (orderError) {
      handleOrderError(orderError, status)
    } else if (redirectData) {
      setNexio3DsUrl(redirectData.redirectUrl)
      setNexioRedirectData(redirectData)
    } else {
      handleSuccess()
    }
  }

  // paypal
  const [showPayPalButton, setShowPayPalButton] = useState(false)
  useEffect(() => {
    if (!cartData) return
    const hasCBD = doesCartHaveCBD(cartData)
    const isAuOrNz = localeCountryCode === 'au' || localeCountryCode === 'nz'
    setShowPayPalButton(!hasCBD && !isAuOrNz)
  }, [cartData, localeCountryCode])

  // nexio3ds
  useEffect(() => {
    if (!nexio3DsUrl) return
    setShowNexio3dsModal(true)
  }, [nexio3DsUrl])

  const handlePayPalClick = async () => {
    setIsRenewalPending(true)
    if (isPcOfferInCart) {
      const data = {
        ...qUser,
        areTermsAccepted,
        receiveEmail: true,
        receiveText: true,
      }

      await updateQUser(data).then(() => {
        // handleSetUserState({ isEnrollmentComplete: true })
      })
    }
  }

  const { currency = '', value: amount = 0 } =
    cartData?.prices?.grand_total ?? {}

  const taxValue =
    cartData?.prices?.subtotal_including_tax?.value -
      cartData?.prices?.subtotal_excluding_tax?.value || 0

  const shippingTotal = shippingMethod ? shippingMethod?.amount?.value ?? 0 : 0

  const conditionalAmount =
    isPcOfferInCart || isAmbOfferInCart
      ? cartData?.wholesale_total + taxValue
      : amount

  const payPalTotal = (conditionalAmount + shippingTotal).toFixed(2).toString()

  const createPayPalOrder = (_, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency_code: currency,
              value: payPalTotal,
            },
          },
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then(orderId => {
        Sentry.captureMessage(
          `PayPal Order ID: ${orderId} by ${qUser.emailAddress}`
        )
        return orderId
      })
  }

  const handlePayPalApproved = async (data, actions) => {
    const isPaypalMethod = true
    return actions.order.capture().then(async () => {
      window.scrollTo(0, 0) // scroll to top of page
      let { orderNumber, error, status } = await placeOrder(
        setIsPlacingOrder,
        cartData,
        getSelectedCard,
        buildCart,
        handleGetCartData,
        isPaypalMethod,
        data?.orderID
      )
      setCartState(prev => ({ ...prev, orderNumber }))
      if (error) handleOrderError(error, status)
      else {
        localStorage.setItem('showArqModal', isAmbOfferInCart && 'true')
        analytics('purchase')
        setCartState(prev => ({
          ...prev,
          showConfirmation: true,
        }))
      }
    })
  }

  const onPayPalError = error => {
    if (error) {
      handleOrderError(error, 'PayPal failed to process order')
      Sentry.captureException(error)
    }
  }

  const isEventOrVip = isEventSite || isVipSite

  const shippingTo = () => {
    if (isEventOrVip) {
      return (
        <StyledSpan bold red>
          {`${event_name} Event Pickup`}
        </StyledSpan>
      )
    }
    let shippingText = which_address_should_we_use[0].text
    if (!isShippingSet && !magentoUser?.addresses?.length)
      shippingText = please_add_shipping[0].text
    if (isShippingSet && shippingAddress) {
      shippingText = `${shippingAddress?.street[0]}`
    }

    return (
      <Link to="/shipping-info">
        <StyledSpan bold>{shippingText}</StyledSpan>
      </Link>
    )
  }

  const cardInfo = () => {
    let cardText = lastFourDigits
      ? lastFourDigits
      : please_add_a_payment_method[0].text
    return (
      <Link to="/payment-info">
        <StyledSpan bold>{cardText}</StyledSpan>
      </Link>
    )
  }

  const handleAgreementChecked = (e, { name }) => {
    name === 'smsEmail'
      ? setCartState(prev => ({ ...prev, receiveSmsEmail: !receiveSmsEmail }))
      : setCartState(prev => ({ ...prev, areTermsAccepted: !areTermsAccepted }))
  }

  const isAProductOutOfStock = cartData?.items?.some(
    ({ product: { stock_status } }) => stock_status === OUT_OF_STOCK
  )

  const shoppingWith = () => {
    if (!isAuthenticated) {
      if (isReferral && referralData?.ambassadorName)
        return referralData?.ambassadorName
      return 'Q Sciences'
    }
    if (qUserUpline?.displayName === 'Par Partners LLC') return 'Q Sciences'

    return qUserUpline?.displayName
  }

  //get total pv from the cartData
  const totalPV = (): number => {
    if (!cartData) return 0
    return cartData?.items?.reduce((acc, item) => {
      return acc + parseInt(item?.product.pv) * item?.quantity
    }, 0)
  }

  const shouldShowSavings =
    isPcOfferInCart ||
    (isAmbOfferInCart &&
      userType === 'RETAIL' &&
      cartData?.total_wholesale_savings) ||
    (userType !== 'RETAIL' && cartData?.total_savings)

  const shouldHidePcOffer =
    isPcOfferInCart ||
    isAmbOfferInCart ||
    userType !== 'RETAIL' ||
    isVipSite ||
    isEventSite

  const showAutoShip =
    !(isVipSite || isEventSite) &&
    (isPcOfferInCart || isAmbOfferInCart || userType !== 'RETAIL')

  const shouldShowShipping = !isEventOrVip

  // amb renewals
  useEffect(() => {
    const shouldShowRenewalForAmb =
      expiredSubscription !== null &&
      userType === 'AMBASSADOR' &&
      isAuthenticated &&
      !isAmbOfferInCart &&
      !isRenewalPending
    setShowRenewalModalForAmb(shouldShowRenewalForAmb)
  }, [expiredSubscription, userType, isAuthenticated, isAmbOfferInCart])

  if (!cartData)
    return <Loading loading={true} message={loading_cart[0].text} />

  if (isPlacingOrder)
    return (
      <Loading loading={isPlacingOrder} message={processing_order[0].text} />
    )

  return (
    <>
      <Seo title={title[0].text} />
      <Wrapper>
        <>
          <PageHeader exitRoute="/">{header[0].text}</PageHeader>
          <Container padded>
            {userType === 'AMBASSADOR' ? (
              <StyledSpan data-qa="ambName" isTitle>
                {qUser?.displayName}
              </StyledSpan>
            ) : (
              <StyledSpan>
                {thanks_for_shopping_with[0].text}{' '}
                <StyledSpan data-qa="uplineName" isTitle>
                  {shoppingWith()}
                </StyledSpan>
              </StyledSpan>
            )}
            {isAuthenticated ? (
              <>
                <StyledSpan data-qa="shippingAddress">
                  {shipping_to[0].text} {shippingTo()}
                </StyledSpan>
                <StyledSpan data-qa="cardInfo">
                  {card_ending_in[0].text} {cardInfo()}
                </StyledSpan>
              </>
            ) : null}
            <OrderDetails
              {...cartData}
              shipping={shippingMethod}
              showShippingAndTax={isAuthenticated}
              showWholesaleTotal={isPcOfferInCart || isAmbOfferInCart}
              shippingTotal={shippingTotal}
              taxValue={taxValue}
              totalPV={totalPV()}
            />
            {shouldShowSavings ? (
              <TotalSavings
                savings={
                  isPcOfferInCart || isAmbOfferInCart
                    ? cartData.total_wholesale_savings
                    : cartData.total_savings
                }
                isPcMessage={isPcOfferInCart || userType === 'PREFERRED'}
              />
            ) : null}
            {isAuthenticated && <AddCoupon />}
          </Container>
          <Container padded>
            {cartData?.items?.map(item => (
              <div key={item.uid}>
                <CartProductCard item={item} />
              </div>
            ))}
          </Container>
          {shouldHidePcOffer ? null : (
            <PcOffer addPcOfferToCart={upgradeUserAddPcFee} />
          )}
          {isAuthenticated ? (
            <>
              {shouldShowShipping ? (
                <ShippingDetails
                  address={shippingAddress}
                  showAddress={isShippingSet}
                />
              ) : null}
              <PaymentDetails
                ccType={creditCardType}
                lastFour={lastFourDigits}
              />
            </>
          ) : null}
          {isPcOfferInCart && isAuthenticated ? (
            <PcAgreement
              receiveSmsEmail={receiveSmsEmail}
              areTermsAccepted={areTermsAccepted}
              handleChecked={handleAgreementChecked}
            />
          ) : null}
          <Container style={{ marginTop: 'auto' }} align="center">
            {isAuthenticated ? (
              <>
                {showPayPalButton &&
                  cartData?.shipping_addresses?.length > 0 && (
                    <PayPalButton
                      onClick={handlePayPalClick}
                      createOrder={createPayPalOrder}
                      onApprove={handlePayPalApproved}
                      onError={onPayPalError}
                      currency={currency}
                      amount={payPalTotal}
                      disabled={
                        isAProductOutOfStock ||
                        (isAmbOfferInCart && !isEnrollmentComplete) ||
                        (isPcOfferInCart && !areTermsAccepted) ||
                        cartData?.items?.length === 0 ||
                        (cart_max &&
                          cartData?.prices?.subtotal_excluding_tax?.value >
                            cart_max)
                      }
                    />
                  )}
                <PrimaryButton
                  style={{ margin: '2em 0' }}
                  onClick={handlePlaceOrder}
                  content={place_order[0].text}
                  disabled={
                    cartData?.shipping_addresses?.length === 0 ||
                    !lastFourDigits ||
                    isAProductOutOfStock ||
                    (isPcOfferInCart && !areTermsAccepted) ||
                    cartData?.items?.length === 0 ||
                    (cart_max &&
                      cartData?.prices?.subtotal_excluding_tax?.value >
                        cart_max)
                  }
                />
                {showAutoShip ? (
                  <PrimaryButton
                    onClick={() => navigate('/subscriptions')}
                    content="Manage Subscription"
                    style={{
                      marginTop: '0',
                    }}
                  />
                ) : null}
              </>
            ) : (
              <PrimaryButton
                style={{ margin: '1em 0' }}
                onClick={() => navigate('/login')}
                content={continue_to_checkout[0].text}
              />
            )}
          </Container>
        </>
        {showConfirmation && orderNumber ? (
          <OrderConfirmation
            orderNumber={orderNumber}
            email={cartData.email}
            isEventSite={isEventOrVip}
            shouldRefresh={isEnrollmentComplete || areTermsAccepted}
            handleRefresh={() => {
              handleRefreshSite()
              setIsRenewalPending(false)
            }}
          />
        ) : null}
        {showOrderError && orderNumber ? (
          <OrderFailed
            orderNumber={orderNumber}
            orderStatus={orderStatus}
            userName={qUser?.displayName}
            errorData={orderError}
          />
        ) : null}
        {showRenewalModalForAmb && (
          <RenewalModalForAmb open={showRenewalModalForAmb} disableOnClose />
        )}
        <Nexio3dsModal
          open={showNexio3dsModal}
          onClose={handleNexio3dsModalClose}
          onYes={handleNexio3dsModalYes}
        />
      </Wrapper>
    </>
  )
}

export default CartPage
